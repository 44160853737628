import React from 'react';
import Stage from './Stage';
import InfoModal from './InfoModal';
import Curtains from './Curtains';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeHotSpot: 'hs0',
      showInfoModal: false,
      playing: true,
      curtains: false,
    };

    this.handleActiveHotSpotChange = this.handleActiveHotSpotChange.bind(this);
    this.handleShowInfoModalChange = this.handleShowInfoModalChange.bind(this);
    this.handlePlayingChange = this.handlePlayingChange.bind(this);
    this.handleVideoEnded = this.handleVideoEnded.bind(this);
    this.handleWatchAgain = this.handleWatchAgain.bind(this);
  }

  handleActiveHotSpotChange(activeHotSpot) {
    this.setState((state) => ({
        activeHotSpot: activeHotSpot,
        showInfoModal: state.showInfoModal,
        playing: state.playing
    }));
  }

  handleShowInfoModalChange(showInfoModal) {
    this.setState((state) => ({
      showInfoModal: showInfoModal,
      activeHotSpot: state.activeHotSpot,
      playing: state.playing
    }));
  }

  handlePlayingChange(playing) {
    this.setState((state) => ({
      playing: playing,
      activeHotSpot: state.activeHotSpot,
      showInfoModal: state.showInfoModal
    }));
  }

  handleWatchAgain() {
    document.body.classList.remove('curtainsDrawn');
    this.setState((state) => ({
      playing: state.playing,
      activeHotSpot: state.activeHotSpot,
      showInfoModal: state.showInfoModal,
      curtains: false,
    }));
  }

  handleVideoEnded() {
    document.body.classList.add('curtainsDrawn');
    this.setState((state) => ({
      playing: state.playing,
      activeHotSpot: state.activeHotSpot,
      showInfoModal: state.showInfoModal,
      curtains: true,
    }));
  }

  render() {
    if (this.state.curtains) {
      return(
        <div className="App curtainsDrawn">
          <Curtains show={this.state.curtains}
                    onWatchAgain={this.handleWatchAgain} />
        </div>
      )
    }

    return (
      <div className="App">
        <Stage onActiveHotSpotChange={this.handleActiveHotSpotChange}
               onShowInfoModalChange={this.handleShowInfoModalChange}
               playing={this.state.playing}
               onPlayingChange={this.handlePlayingChange}
               onVideoEnded={this.handleVideoEnded} />
        <InfoModal showInfoModal={this.state.showInfoModal}
                   activeHotSpot={this.state.activeHotSpot}
                   onShowInfoModalChange={this.handleShowInfoModalChange}
                   onPlayingChange={this.handlePlayingChange} />
      </div>
    );
  }
}

export default App;
