import React from 'react';
import VideoPlayer from './VideoPlayer';
import { HotSpot, ContentSpot } from './HotSpot';

class Stage extends React.Component {
  constructor(props) {
    super(props);

    this.pause = this.pause.bind(this);
    this.pauseAndShowModal = this.pauseAndShowModal.bind(this);
    this.state = {
      currentTime: 0
    };
  }

  componentDidMount() {
    this.player = document.getElementById("MainVideo").player;
    this.player.stage = this;
    this.player.on('timeupdate', function (e) {
      this.stage.updateVideoTime();
    });
    this.player.on('ended', function(){
      this.stage.props.onVideoEnded()
    });
  }

  componentDidUpdate(prevProps) {
    const { playing } = this.props;

    if (prevProps.playing === playing ) return;

    if (playing) {
      if (this.player.paused()) this.player.play();
    } else {
      if (!this.player.paused()) this.player.pause();
    }
  }

  updateVideoTime() {
    let currentTime = this.player.currentTime();

    this.setState((state, props) => ({
      currentTime: currentTime
    }));
  }

  pause() {
    this.props.onPlayingChange(false);
    this.player.pause();
  }

  pauseAndShowModal(activeHotSpot) {
    this.player.pause();
    this.props.onActiveHotSpotChange(activeHotSpot);
    this.props.onShowInfoModalChange(true);
    this.props.onPlayingChange(false);
  }

  render() {
    return (
      <section className="Stage">
        <VideoPlayer id="MainVideo" />

        <HotSpot id="hs-pi"
                 className="HotSpot"
                 currentTime={ this.state.currentTime }
                 startsAt={ 4 }
                 endsAt={ 155 }
                 pause={ this.pause }
                 target="_blank"
                 linkHref={ "https://www.gehealthcare.com/-/jssmedia/gehc/us/files/products/contrast-media/optison/optison-pi_03-2021_1199798-bk.pdf?rev=-1.pdf" } />

        <ContentSpot id="hs0"
                     className="HotSpot"
                     currentTime={ this.state.currentTime }
                     startsAt={ 41 }
                     endsAt={ 48 }
                     pauseAndShowModal={ this.pauseAndShowModal } />

        <ContentSpot id="hs1"
                     className="HotSpot"
                     currentTime={ this.state.currentTime }
                     startsAt={ 59 }
                     endsAt={ 65 }
                     pauseAndShowModal={ this.pauseAndShowModal } />

        <ContentSpot id="hs2"
                     className="HotSpot"
                     currentTime={ this.state.currentTime }
                     startsAt={ 68 }
                     endsAt={ 79 }
                     pauseAndShowModal={ this.pauseAndShowModal } />

        <ContentSpot id="hs3"
                     className="HotSpot"
                     currentTime={ this.state.currentTime }
                     startsAt={ 108 }
                     endsAt={ 115 }
                     pauseAndShowModal={ this.pauseAndShowModal } />

        <HotSpot id="hs-pill0"
                 className="HotSpot"
                 currentTime={ this.state.currentTime }
                 startsAt={ 142 }
                 endsAt={ 155 }
                 pause={ this.pause }
                 target="_blank"
                 linkHref={ "https://landing1.gehealthcare.com/USC-GA-22-02-PDx-CON-Optison_RWIVideo_test2.html" } />

        <HotSpot id="hs-pill1"
                 className="HotSpot"
                 currentTime={ this.state.currentTime }
                 startsAt={ 143 }
                 endsAt={ 155 }
                 pause={ this.pause }
                 target="_blank"
                 linkHref={ "https://landing1.gehealthcare.com/USC-GA-22-02-PDx-CON-Optison_RWIVideo_test2.html" } />

        <HotSpot id="hs-pill2"
                 className="HotSpot"
                 currentTime={ this.state.currentTime }
                 startsAt={ 144 }
                 endsAt={ 155 }
                 pause={ this.pause }
                 target="_blank"
                 linkHref={ "https://landing1.gehealthcare.com/USC-GA-22-02-PDx-CON-Optison_RWIVideo_test2.html" } />
      </section>
    );
  }
}

export default Stage;
