import React from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function hs0Title() {
  return(
    <h4>WHICH PATIENTS COULD BENEFIT MOST FROM OPTISON?</h4>
  )
}

function hs1Title() {
  return(
    <h4>LEARN MORE ABOUT THE ALBUMIN SHELL</h4>
  )
}

function hs2Title() {
  return(
    <h4>HOW CAN I ADMINISTER OPTISON?</h4>
  )
}

function hs3Title() {
  return(
    <h4>FROM PRESENTATION TO DIAGNOSIS - OPTISON IN PRACTICE</h4>
  )
}

function Title(props) {
  switch (props.activeHotSpot) {
  case 'hs0':
    return(hs0Title());
  case 'hs1':
    return(hs1Title());
  case 'hs2':
    return(hs2Title());
  case 'hs3':
    return(hs3Title());
  default:
    console.error(`No match for ${props.activeHotSpot}.`);
  }
}

function hs0Main() {
  return(
    <>
      <p className="chunk">
        Incomplete endocardial resolution occurs in approximately 10%-15%
        <sup>1</sup> of routine echocardiograms and approximately 25%-30% of
        echocardiograms performed in the ICU. <sup>2</sup> Patients who are
        obese, have lung disease, are critically ill, or are receiving
        ventilator care often have suboptimal echocardiograms.<sup>3</sup>
      </p>
      <p className="chunk">
        According to the American Society of Echocardiography (ASE), “The use of
        ultrasound enhancing agents (UEAs) has become an integral component of
        echocardiography practice.” With UEAs, suboptimal echocardiograms (i.e.,
        nonvisualization of two or more segments) can be converted to diagnostic
        examinations.<sup>4</sup> The 2021 Intersocietal Accreditation
        Commission (IAC) Standards recommends UEA use when two or more left
        ventricular (LV) segments cannot be visualized adequately for the
        assessment of LV function and/or in settings in which the study
        indication requires accurate analysis of regional wall
        motion.<sup>5</sup>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </p>
      <p>
        <strong> Important Safety Information About OPTISON </strong>
      </p>
      <p>
        <strong>OPTISON</strong> (Perflutren Protein-Type A Microspheres Injectable Suspension,
        USP) is indicated for use in patients with suboptimal echocardiograms to
        opacify the left ventricle and to improve the delineation of the left
        ventricular endocardial borders.
      </p>
      <p>
        <strong>CONTRAINDICATION:</strong> Do not administer OPTISON to patients with known or
        suspected hypersensitivity to perflutren or albumin.
      </p>
      <p>
        <strong>
          Please see <a href="https://www.gehealthcare.com/-/jssmedia/gehc/us/files/products/contrast-media/optison/optison-pi_03-2021_1199798-bk.pdf?rev=-1.pdf" target="_blank">Important Safety Information</a> including Boxed
          Warning, and Full Prescribing Information for additional important
          safety information.
        </strong>
      </p>
    </>
  );
}

function hs1Main() {
  return(
    <>
      <p className="chunk">
        Products containing albumin are used in many areas in hospitals,
        including nuclear medicine, emergency medicine and general medicine
      </p>
      <hr/>
      <p className="chunk">
        Optison does not contain synthetic phospholipids or polyethylene glycol
        (PEG). Optison uses albumin, a naturally occurring protein produced in
        the liver
      </p>
      <hr/>
      <p className="chunk">
        Optison has small microspheres composed of perflutren gas encapsulated
        by a natural albumin shell
      </p>
      <hr/>
      <p className="chunk">
        Optison is the only ultrasound enhancing agent in the U.S. with an
        albumin shell
      </p>
      <hr/>
      <p className="chunk">
        Optison is not a blood product, it is classified by the FDA as a medical
        imaging drug product.
      </p>
      <p className="chunk sky-blue">
        OPTISON HAS A WELL-ESTABLISHED SAFETY PROFILE SUPPORTED BY ITS NEW DRUG
        APPLICATION (NDA) CLINICAL TRIAL AND MULTIPLE POST-MARKETING CLINICAL
        TRIALS.
      </p>
      <p className="chunk">
        The most common adverse events reported are headache, nausea and/or
        vomiting, warm sensation/flushing, and dizziness.
      </p>
    </>
  );
}

function hs2Main() {
  return(
    <>
      <p className="chunk sky-blue">
        PORTABILITY: READY WHERE YOU ARE
      </p>
      <p className="chunk">
        Easy resuspension allows quick access to Optison anywhere in the
        hospital that an enhanced echocardiogram needs to be performed
      </p>
      <hr/>
      <p className="chunk">
        No vial mixer or special equipment required to resuspend
      </p>
      <hr/>
      <p className="chunk">
        Stable at room temperature for up to 24 hours
      </p>
      <hr/>
      <p className="chunk">
        If unopened, Optison can be returned safely to refrigerator for use
        at a later time
      </p>
      <br/>
      <p className="chunk sky-blue">
        FAST PREPARATION: FROM RESUSPENSION TO INJECTION
      </p>
      <p className="chunk">
        Simply resuspend the microspheres by rotating the vial in your hands
      </p>
      <hr/>
      <p className="chunk">
        Resuspend if more than 1 minute has passed, or if the solution starts
        to separate, by gently rotating the syringe
      </p>
      <br/>
      <p className="chunk sky-blue">
        FLEXIBLE DOSING: SIMPLE STEPS FOR INJECTION
      </p>
      <p className="chunk">
        Optimal enhancement is obtained immediately after administration
      </p>
      <hr/>
      <p className="chunk">
        The recommended dose is 0.5mL through an IV
      </p>
      <hr/>
      <p className="chunk">
        You may administer additional doses of 0.5mL as needed, up to 5.0mL
        in any 10-minute period. Do not exceed a total dose of 8.7mL
      </p>
      <hr/>
      <p className="chunk">
        Inject Optison at a rate not to exceed 1mL per second
      </p>
      <hr/>
      <br />
      <p>
        <strong>
          For further information about Optison Dosing and Administration,
          please see the supporting material at the end of this video
        </strong>
      </p>
    </>
  );
}

function Area(props) {
  return(
    <>
      <Col xxl="3">
        <h4>{props.areaName}</h4>
        <section className="area">
          <section className="unenhanced">
            <h5>Unenhanced</h5>
            <img alt={`Unenhanced ${props.id}`} src={`u${props.id}.gif`}/>
          </section>
        </section>
      </Col>
      <Col xxl="3">
        {/* <h4>{props.areaName}</h4> */}
        <section className="area">
          <section className="enhanced">
            <h5>Optison-enhanced</h5>
            <img alt={`Enhanced ${props.id}`} src={`e${props.id}.gif`}/>
          </section>
        </section>
      </Col>
    </>
  );
}

function Echocardiograms() {
  return(
    <>
      <Container fluid className="echocardiograms">
        <Row>
          <Area id="PLAX" areaName="PARASTERNAL LONG AXIS (PLAX)"/>
          <Area id="PSAX" areaName="PARASTERNAL SHORT AXIS (PSAX)"/>
        </Row>
        <Row>
          <Area id="A2C" areaName="APICAL 2 CHAMBER (A2C)"/>
          <Area id="A4C" areaName="APICAL 4 CHAMBER (A4C)"/>
        </Row>
      </Container>
    </>
  );
}

function hs3Main() {
  return(
    <>
      <h3>
        CASE STUDIES ASSESSING THE USE OF OPTISON SHOW IT CAN BE A SIMPLE-TO-USE
        SOLUTION IN HARD-TO-TREAT PATIENTS
      </h3>
      <br/>
      <p className="chunk sky-blue">
        A PATIENT WITH SUSPECTED OR ESTABLISHED CARDIOVASCULAR
        DISEASE AND A HISTORY OF CHEST PAIN PRESENTS IN PRACTICE:
      </p>
      <p className="chunk">
        An 85-year-old female with elevated troponin and history of:
        hypertension, coronary artery disease, cerebrovascular accident,
        breast cancer, hyperlipidemia, peripheral vascular disease, and
        atrial fibrillation
      </p>
      <br/>
      <p className="chunk sky-blue">
        TO PRODUCE VALUABLE INFORMATION FOR DIAGNOSIS AND MANAGEMENT, THE
        CARDIAC ANATOMY MUST BE VISUALIZED:
      </p>
      <p className="chunk">
        An echocardiogram is ordered to assess left ventricle (LV) structure and
        function
      </p>
      <hr/>
      <p className="chunk">
        Due to poor LV endocardial border definition, Optison™ is used to
        enhance the scan
      </p>
      <br/>
      <p className="chunk sky-blue">
        THE OPTISON-ENHANCED ECHOCARDIOGRAM SHOWS THE IMPROVED
        DELINEATION OF THE LV ENDOCARDIAL BORDERS VERSUS A SCAN WITH
        NO ENHANCING AGENT
      </p>

      <Echocardiograms/>

      <br/>
      <p className="chunk sky-blue">
        BY USING OPTISON TO ENHANCE THE ECHOCARDIOGRAM, THE INTERPRETING
        PHYSICIAN WAS ABLE TO CONCLUDE:
      </p>
      <p className="chunk">
        LV cavity size is normal
      </p>
      <hr/>
      <p className="chunk">
        LV wall thickness is moderately increased
      </p>
      <hr/>
      <p className="chunk">
        Systolic function is mildly reduced with an estimated LV ejection
        fraction of 45-50%
      </p>
      <hr/>
      <p className="chunk">
        No evidence of thrombus
      </p>
      <hr/>
      <p className="chunk">
        A small pericardial effusion is noted
      </p>
      <br/>
      <br/>
      <br/>

      <p>
        <strong> Important Safety Information About OPTISON </strong>
      </p>
      <p>
        <strong> CONTRAINDICATIONS: </strong> Do not administer OPTISON to
        patients with known or suspected hypersensitivity to perflutren or
        albumin. <br />
        <strong> WARNINGS AND PRECAUTIONS </strong> — Serious Cardiopulmonary
        Reactions: Serious cardiopulmonary reactions, including fatalities, have
        occurred uncommonly during or shortly following administration. The risk
        for these reactions may be increased among patients with unstable
        cardiopulmonary conditions (acute myocardial infarction, acute coronary
        artery syndromes, worsening or unstable congestive heart failure, or
        serious ventricular arrhythmias). Always have cardiopulmonary
        resuscitation personnel and equipment readily available prior to Optison
        administration, and monitor all patients for acute reactions.
      </p>
      <p>
        <strong>
          Please see <a href="https://www.gehealthcare.com/-/jssmedia/gehc/us/files/products/contrast-media/optison/optison-pi_03-2021_1199798-bk.pdf?rev=-1.pdf" target="_blank">Important Safety Information</a> including Boxed
          Warning, and Full Prescribing Information for additional important
          safety information.
        </strong>
      </p>
    </>
  );
}

function hs0Safety() {
  return(
    <ol className="hs0">
      <li>
        Senior R, et al. Clinical benefits of contrast-enhanced echocardiography
        during rest and stress examinations. Eur J Echocardiogr.
        2005;6(2):S6-S13.
      </li>
      <li>
        Kurt M, et al. Impact of contrast echocardiography on evaluation of
        ventricular function and clinical management in a large prospective
        cohort. J Am Coll Cardiol. 2009;53:802-810.
      </li>

      <li>
        Mulvagh SL, et al. American Society of Echocardiography consensus
        statement on the clinical applications of ultrasonic contrast agents in
        echocardiography. J Am Soc Echocardiogr. 2008;21(11):1179-1201.
      </li>

      <li>
        Porter TR, et al. Clinical applications of ultrasonic enhancing agents
        in echocardiography: 2018 American Society of Echocardiography
        Guidelines Update. J Am Soc Echocardiogr. 2018;31:241-247.
      </li>

      <li>
        Intersocietal Accreditation Commission website. IAC Standards and
        Guidelines for Adult Echocardiography Accreditation.
        http://www.intersocietal.org/echo/
        seeking/echo_standards.htm. Updated
        May 15, 2021. Accessed October 28, 2021.
      </li>
    </ol>
  );
}

function hs1Safety() {
  return(
    <>
      <p>
        <strong>
          Between 2016 -2020, 37 Adverse
          Events (AE) were received by the FDA
          Adverse Event Reporting System
          (FAERS).
        </strong>
      </p>
      <p>
        <strong>
          Important Safety Information
          About OPTISON
        </strong>
      </p>
      <p>
        <strong> Adverse Events: </strong> The most frequently reported adverse
        reactions in clinical trials were headache, nausea and/or vomiting, warm
        sensation or flushing and dizziness. Cardiac arrests and other serious but
        nonfatal adverse reactions were uncommonly reported in post-approval use.
        Reports also identified neurologic reactions (loss of consciousness or
        convulsions) as well as anaphylactoid reactions.
      </p>
      <p>
        <strong>
          Please see Boxed Warning, additional Important Safety Information and
          link to the full Prescribing Information at the end of this video.
        </strong>
      </p>
    </>
  );
}

function hs2Safety() {
  return(
    <>
      <p>
        <strong>
          Important Safety Information
          About OPTISON
        </strong>
      </p>
      <p>
        <strong> Adverse Events: </strong> The most frequently reported adverse
        reactions in clinical trials were headache, nausea and/or vomiting, warm
        sensation or flushing and dizziness. Cardiac arrests and other serious
        but nonfatal adverse reactions were uncommonly reported in post-approval
        use. Reports also identified neurologic reactions (loss of consciousness
        or convulsions) as well as anaphylactoid reactions.
      </p>
      <p>
        <strong>
          Please see Product Indications and additional Important Safety
          Information About OPTISON, and full Prescribing Information.
        </strong>
      </p>
    </>
  );
}

function hs3Safety() {
  return(
    <>
      <p>
        <strong>
          Warning: Serious Cardiopulmonary Reactions
        </strong>
      </p>
      <p>
        Serious cardiopulmonary reactions, including fatalities, have occurred
        uncommonly during or following perflutren-containing microsphere
        administration. Most serious reactions occur within 30 minutes of
        administration
      </p>
      <ul>
        <li>
          Assess all patients for the presence of
          any condition that precludes OPTISON
          administration
        </li>
        <li>
          Always have resuscitation equipment
          and trained personnel readily available
        </li>
      </ul>
    </>
  );
}

function Main(props) {
  switch (props.activeHotSpot) {
  case 'hs0':
    return(hs0Main());
  case 'hs1':
    return(hs1Main());
  case 'hs2':
    return(hs2Main());
  case 'hs3':
    return(hs3Main());
  default:
    console.error(`No match for ${props.activeHotSpot}.`);
  }
}

function Safety(props) {
  switch (props.activeHotSpot) {
  case 'hs0':
    return(hs0Safety());
  case 'hs1':
    return(hs1Safety());
  case 'hs2':
    return(hs2Safety());
  case 'hs3':
    return(hs3Safety())
  default:
    console.error(`No match for ${props.activeHotSpot}.`);
  }
}

function CloseButton(props) {
  return(
    <button type="button" className="btn" onClick={props.handleClose} aria-label="Close">
      <svg version="1.1" viewBox="0 0 54.4 54.4" xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(1.3333 0 0 -1.3333 -41.182 1402.2)">
          <g transform="translate(-1698.3 80.847)">
            <g transform="translate(1749.6 970.8)">
              <path d="m0 0c-11.267 0-20.4-9.133-20.4-20.4s9.133-20.4 20.4-20.4 20.4 9.133 20.4 20.4-9.133 20.4-20.4 20.4m0-1.145c10.617 0 19.255-8.638 19.255-19.255s-8.638-19.255-19.255-19.255-19.255 8.638-19.255 19.255 8.638 19.255 19.255 19.255" fill="#fff" />
            </g>
            <g transform="translate(1742.1 957.88)">
              <path d="m0 0 14.91-14.91" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1.145" />
            </g>
            <g transform="translate(1757.1 957.88)">
              <path d="m0 0-14.865-14.898" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1.145" />
            </g>
          </g>
        </g>
      </svg>
    </button>
  )
}

function ScrollButton(props) {
  if(props.hide) return null;
  return(
    <button type="button" className={`btn btn-scroll ${props.direction}`} onClick={props.handleScroll} aria-label="Close">
      <svg width="80.219" height="27.437" version="1.1" viewBox="0 0 21.225 7.2593" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(-1.7569 -2.0655)">
          <g transform="matrix(.35278 0 0 -.35278 22.794 2.3642)">
            <path d="m0 0-29.55-18.55-29.551 18.55" fill="none" stroke="#00b5e2" strokeMiterlimit="10" strokeWidth="2"/>
          </g>
        </g>
      </svg>
    </button>
  )
}

function BackButton(props) {
  return(
    <button type="button" className="btn btn-back" onClick={props.handleClose}>
      <svg version="1.1" viewBox="0 0 13.408 28.933" xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(1.3333 0 0 -1.3333 -.24589 1439.8)">
          <g transform="translate(9.5913 1058.7)">
            <path d="m0 0-8.359 10.338 8.375 10.337" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1.629" />
          </g>
        </g>
      </svg>
      BACK TO VIDEO
    </button>
  )
}

export { Title, Main, Safety, CloseButton, ScrollButton, BackButton }
