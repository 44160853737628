// By default, we don't show the fullscreen toggle control, as that is not
// appropriate for viewers who navigate to roll-with-it.sevenstones.co.uk, or
// for users of Showpad.

// If we are running embedded on the corporate site, then the parent frame should
// let us know such that we can display the fullscreen toggle control.

// This also feeds into Google Analytics, as we want to discriminate between
// corporate and Showpad visits.
class IFrameParentConfiguration {
  constructor() {
    window.addEventListener('message', this.receiveParentMessage);
    this.videoHost = 'Unknown'; // May feed into GA

    if (this.notInIframe()) {
      this.videoHost = 'Direct';
    }
  }

  notInIframe = () => {
    try {
      return window.self === window.top;
    } catch (e) {
      return false;
    }
  };

  receiveParentMessage = (event) => {
    if(event.data && (typeof event.data.match === 'function') && event.data.match(/messageForVideo:/)) {
      if(event.data.match(/InCorporateSite/)) {
        document.body.classList.remove('embeddedInShowpad');
        document.body.classList.add('embeddedInCorporateSite');
        this.videoHost = 'Corporate'; // May feed into GA
      }
      if(event.data.match(/InShowpad/)) {
        document.body.classList.remove('embeddedInCorporateSite');
        document.body.classList.add('embeddedInShowpad');
        this.videoHost = 'Showpad'; // May feed into GA
      }
    };
  };
}

export default IFrameParentConfiguration;
