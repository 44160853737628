import React from 'react';

class HotSpotBase extends React.Component {
  afterStart() {
    return this.props.currentTime > this.props.startsAt;
  }

  beforeEnd() {
    return this.props.currentTime < this.props.endsAt;
  }

  shouldRender() {
    if(this.afterStart() && this.beforeEnd()) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    if(!this.shouldrender()) { return null; };

    return (
      <h1>Overide Me</h1>
    );
  }
}

export class HotSpot extends HotSpotBase {
  render() {
    if(!this.shouldRender()) { return null; }

    return (
      <a href={this.props.linkHref} target="_blank" rel="noreferrer" onClick={ this.props.pause }>
        <div id={ this.props.id } className={ this.props.className }/>
      </a>
    );
  }
}

export class TextSpot extends HotSpotBase {
  render() {
    if(!this.shouldRender()) { return null; }

    return (
      <div id={ this.props.id } className={this.props.className}>
        <h1> { this.props.title }</h1>
        <p> { this.props.body }</p>
      </div>
    );
  }
}

export class LinkSpot extends HotSpotBase {
  render() {
    if(!this.shouldRender()) { return null; }

    return (
      <p id={ this.props.id } className={ this.props.className }>
        <a href={this.props.linkHref} target="_blank" rel="noreferrer" onClick={ this.props.pause }>
          { this.props.linkText }
        </a>
      </p>
    );
  }
}

export class ButtonSpot extends HotSpotBase {
  render() {
    if(!this.shouldRender()) { return null; }

    return (
      <p id={ this.props.id } className={ this.props.className }>
        <button onClick={ this.props.payload }>
          { this.props.linkText }
        </button>
      </p>
    );
  }
}

export class ContentSpot extends HotSpotBase {
  render() {
    if(!this.shouldRender()) { return null; }

    return (
      <div onClick={ () => this.props.pauseAndShowModal(this.props.id) }>
        <div id={ this.props.id } className={ this.props.className }/>
      </div>
    );
  }
}

export class DecisionSpot extends HotSpotBase {
  render() {
    if(!this.shouldRender()) { return null; }

    return (
      <div id={ this.props.id } className={ this.props.className }>
        <a href="\#" onClick={ this.props.decisionOne }>
          <img id="spongebob" src="/spongebob-rainbow.gif" alt="bob"/>
        </a>
        <a href="\#" onClick={ this.props.decisionTwo }>
          <img id="nyancat" src="/nyan-cat.gif" alt="cat"/>
        </a>
      </div>
    );
  }
}
