const videoRatio = (16 / 9)
const approximateVideoRatio = 1.78; // Roughly 16 / 9 with a margin for error
const allowanceForBrowserChrome = 50;

// Class to ensure video fits on screen when devices have Ultra Wide proportions
// (ie. width / height > 16 / 9)
class Sizer {
  constructor() {
    window.addEventListener("orientationchange", this.onSize);
    window.addEventListener("resize", this.onSize);
    this.root = document.getElementById('roll-with-it-video-root');
    this.root.style.margin = "auto";
    this.onSize();
  }

  onSize = (event) => {
    let screenRatio = window.innerWidth / window.innerHeight;

    if(screenRatio > approximateVideoRatio) {
      let maxWidth = ((window.innerHeight - allowanceForBrowserChrome) * videoRatio);
      this.root.setAttribute('extra-wide-device', `${maxWidth}px`);
      this.root.style.maxWidth = `${maxWidth}px`;
    } else {
      if(this.root.hasAttribute('extra-wide-device')) {
        this.root.removeAttribute('extra-wide-device');
        this.root.style.maxWidth = null;
      }
    }
  }
}

export default Sizer;
