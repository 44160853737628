import React from 'react';
import videojs from 'video.js'
import '../node_modules/video.js/dist/video-js.css';

export default class VideoPlayer extends React.Component {
  componentDidMount() {
    const videoJsOptions = {
      id: "MainVideo",
      autoplay: true,
      controls: true,
      fullscreenElement: document.body,
      fluid: true,
      nativeControlsForTouch: false,
      preferFullWindow: true,
      playsinline: true,
      crossorigin: 'anonymous',
      html5: {
        vhs: {
          limitRenditionByPlayerDimensions: false,
          overrideNative: false
        }
      },
      sources: [{
        src: '/media/hls/roll-with-it.m3u8',
        type: 'application/vnd.apple.mpegurl'
      },{
        src: '/media/mp4/roll-with-it.mp4',
        type: 'Video/mp4'
      }]
    };

    // instantiate Video.js
    this.player = videojs(this.videoNode, videoJsOptions);
    window.player = this.player;
    // Uncomment to emulate devices that don't support requestFullscreenElement API (iphone)
    this.player.fsApi_.requestFullscreen = false;

    this.drawDotsOnTimeline();
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }

  drawDotsOnTimeline() {
    let bar = document.getElementsByClassName('vjs-progress-holder')[0];

    let toolTipTexts = [
      "Which patients could benefit from Optison?",
      "Learn more about the albumin shell",
      "How can I administer Optison?",
      "See the results of a case study",
    ];

    for(let i = 0;i < 4; i++){
      let toolTip = document.createElement("div");
      toolTip.classList.add("iv-time-tooltip");
      toolTip.setAttribute('aria-hidden','true');
      toolTip.textContent = toolTipTexts[i];

      let marker = document.createElement("div");
      marker.classList.add("iv-hotspot-marker");
      marker.setAttribute('id','hsm' + i);

      let dot = document.createElement("div");
      dot.classList.add("iv-dot");
      dot.addEventListener("mouseover", function( event ) {
        event.target.firstElementChild.style.visibility = 'visible';
      }, false);
      dot.addEventListener("mouseout", function( event ) {
        event.target.firstElementChild.style.visibility = 'hidden';
      }, false);

      dot.appendChild(toolTip);
      marker.appendChild(dot);
      bar.appendChild(marker);
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div>
        <div data-vjs-player>
          <video ref={ node => this.videoNode = node }
                 className="video-js"
                 allowFullScreen="allowFullScreen"
                 frameBorder="0">
          </video>
        </div>
      </div>
    )
  }
}
