import React from 'react';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import logo from './optison-logo.svg';
import pi from './pi.svg';

function Pill(props) {
  return(
    <Row>
      <Col xl={3}/>
      <Col xl={6}>
        <a href={props.href} target="_blank" rel="noreferrer">
          <p className="Pill">{props.text}</p>
        </a>
      </Col>
      <Col xl={3}/>
    </Row>
  );
}

class Curtains extends React.Component {
  watchAgain = () => {
    this.props.onWatchAgain();
  };

  handleScrollDown = () => {
    let content = document.querySelector('.content-pane')
    // certain browsers have a bug such that scrollHeight is too small
    // when content does not fill the client area of the element
    var scrollHeight = Math.max(content.scrollHeight, content.clientHeight);
    content.scrollTop = scrollHeight - content.clientHeight;
  };

  render() {
    if(!this.props.show) return null;
    return (
      <>
        <div className="Curtains">
          <Container className="" fluid>
            <div className="d-flex justify-content-between">
              <img className="logo" src={logo} alt="Logo" />
              <a href="https://www.gehealthcare.com/-/jssmedia/gehc/us/files/products/contrast-media/optison/optison-pi_03-2021_1199798-bk.pdf?rev=-1.pdf" target="_blank">
                <img className="pi" src={pi} alt="Prescribing information" />
              </a>
            </div>
            <Row>
              <Col xl={3}/>
              <Col xl={6}>
                <p className="learn">
                  To learn more about Optison and the topics in this film,
                  download a piece of supporting material below:
                </p>
              </Col>
              <Col xl={3}/>
            </Row>
            <Pill text="Albumin-Shelled Microsphere Visual Aid" href="https://landing1.gehealthcare.com/USC-GA-22-02-PDx-CON-Optison_RWIVideo_test2.html"/>
            <Pill text="Dosing, Handling, and Administration Video" href="https://landing1.gehealthcare.com/USC-GA-22-02-PDx-CON-Optison_RWIVideo_test2.html"/>
            <Pill text="Case Studies using Optison" href="https://landing1.gehealthcare.com/USC-GA-22-02-PDx-CON-Optison_RWIVideo_test2.html"/>
            <Row>
              <Col xl={3}/>
              <Col xl={6}>
                <button className='btn Pill small' onClick={this.watchAgain}>
                  <p>PLAY VIDEO AGAIN</p>
                </button>
              </Col>
              <Col xl={3}/>
            </Row>
            <div className="date-stamp d-flex flex-row-reverse">
              <p className="p-4">
                January 2022 JB05339US
              </p>
            </div>
          </Container>
        </div>
      </>
    );
  }
}
export default Curtains;
