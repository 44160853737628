import React from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'

import { Title, Main, Safety, CloseButton, ScrollButton, BackButton } from "./Content";

class InfoModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contentAtBottom: false
    };
  }

  handleShow = () => {
    // We can't use componentDidMount() for this, as the HTML is not rendered
    // unless showInfoModal === true
    this.content = document.querySelector('.content-pane');

    if(this.content && !this.content.getAttribute('listeningToScroll')) {
      this.content.addEventListener('scroll', this.handleScroll);
      this.content.setAttribute('listeningToScroll', true)
    }
  }

  handleClose = () => {
    this.props.onShowInfoModalChange(false);
    this.props.onPlayingChange(true);
  };

  handleScrollDown = () => {
    this.content.scrollTop = this.content.scrollTop + this.content.clientHeight - 40;
  };

  handleScrollUp = () => {
    this.content.scrollTop = 0;
  };

  handleScroll = () => {
    if (this.content.scrollTop + 3 >= (this.content.scrollHeight - this.content.clientHeight)) {
      this.setState({contentAtBottom: true})
    } else {
      this.setState({contentAtBottom: false})
    }
  };

  render() {
    return (
      <>
        <Modal contentClassName={this.props.activeHotSpot} show={this.props.showInfoModal} onShow={this.handleShow} onHide={this.handleClose} onExited={this.handleClose}>
          <Modal.Header>
            <Modal.Title>
              <Title activeHotSpot={this.props.activeHotSpot} />
            </Modal.Title>
            <CloseButton handleClose={this.handleClose} />
          </Modal.Header>
          <Modal.Body>
            <Container fluid>
              <Row>
                <Col className="content-pane" xl={9}>
                  <Main activeHotSpot={this.props.activeHotSpot} />
                </Col>
                <Col className="safety-pane" xl={3}>
                  <Safety activeHotSpot={this.props.activeHotSpot} />
                </Col>
              </Row>
            </Container>

            <Container fluid className="bump-bar">
              <Row>
                <Col className="" xl={9}>
                  <div className="scroll d-flex justify-content-center">
                    <ScrollButton  direction="down" hide={this.state.contentAtBottom} handleScroll={this.handleScrollDown}/>
                    <ScrollButton  direction="up"   hide={!this.state.contentAtBottom} handleScroll={this.handleScrollUp}/>
                  </div>
                </Col>
                <Col className="" xl={3}>
                </Col>
              </Row>
            </Container>
          </Modal.Body>

          <Modal.Footer className="back">
            <BackButton handleClose={this.handleClose}/>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default InfoModal;
