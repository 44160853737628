import React from 'react';
import ReactDOM from 'react-dom';
import './custom_bootstrap.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Sizer from './video-sizer.js';
import IFrameParentConfiguration from './iframe-parent-configuration.js';

const sizer = new Sizer();
const iframeConfig = new IFrameParentConfiguration();
// We default to Showpad mode, which is fullscreen. Direct views on
// roll-with-it.sevenstones.co.uk also use Showpad mode. It is the
// responsibility of any embedding websites to message us and let us now that
// we are running in a non fullscreen iframe.
const defaultToShowpadMode = true; // Don't change this, its really just for documentation.

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('roll-with-it-video-root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

if (defaultToShowpadMode) {
  document.body.classList.add('embeddedInShowpad');
} else {
  // Show the fullscreen toggle control
  document.body.classList.add('embeddedInCorporateSite');
}

// Globals
window.RWI = {};
window.RWI.iframeConfig = iframeConfig;
window.RWI.videoHost = iframeConfig.video_host;
